import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createBlock(_component_a_space, {
    class: "home-pool-statistics flex items-start",
    style: {"gap":"4rem"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statisticList, (stat) => {
        return (_openBlock(), _createElementBlock("div", {
          key: stat.name,
          class: _normalizeClass(["home-pool-statistics__item", stat.name])
        }, [
          _createVNode(_component_a_typography_text, {
            type: "secondary",
            class: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(stat.title), 1)
            ]),
            _: 2
          }, 1024),
          (_ctx.currentEpochLoading)
            ? (_openBlock(), _createBlock(_component_a_skeleton, {
                key: 0,
                class: "home-pool-statistics__item-loading",
                active: "",
                paragraph: false
              }))
            : (_openBlock(), _createBlock(_component_a_typography_title, {
                key: 1,
                level: 3,
                class: "home-pool-statistics__item-value"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(stat.value), 1)
                ]),
                _: 2
              }, 1024))
        ], 2))
      }), 128))
    ]),
    _: 1
  }))
}
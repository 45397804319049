
import { useCurrentEpoch } from "@/shared/composables/useCurrentEpoch";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const { currentEpoch, currentEpochLoading } = useCurrentEpoch();

    const statisticList = computed(() => [
      {
        name: "epoch",
        title: t("Epoch"),
        value: currentEpoch.value?.epoch?.number,
      },
      {
        name: "slot",
        title: t("Slot"),
        value: [currentEpoch.value?.slot, currentEpoch.value?.totalBlocks].join(
          "/"
        ),
      },
      {
        name: "pools",
        title: t("Pools"),
        value: currentEpoch.value?.poolboostPoolCount,
      },
    ]);

    return { t, statisticList, currentEpochLoading };
  },
});


import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import LayoutMain from "@/shared/components/Layouts/LayoutMain.vue";
import PoolList from "@/shared/components/List/PoolList/PoolList.vue";
import HomePoolStatistics from "@/web/views/Home/HomePoolStatistics.vue";
import HomeMissions from "@/web/views/Home/HomeMissions.vue";
import HomeHowItWorks from "@/web/views/Home/HomeHowItWorks.vue";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";

export default defineComponent({
  components: {
    LayoutMain,
    PoolList,
    HomePoolStatistics,
    HomeMissions,
    HomeHowItWorks,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const handleShowPoolDetails = (rowRecord) => {
      if (rowRecord?.hash) {
        router.push({
          name: routeNames.pool,
          params: {
            pool_hash: rowRecord?.hash,
          },
        });
      }
    };

    const handleShowMorePools = () => {
      router.push({ name: routeNames.pools });
    };

    return {
      t,
      handleShowMorePools,
      handleShowPoolDetails,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-home__section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomePoolStatistics = _resolveComponent("HomePoolStatistics")!
  const _component_PoolList = _resolveComponent("PoolList")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_HomeMissions = _resolveComponent("HomeMissions")!
  const _component_HomeHowItWorks = _resolveComponent("HomeHowItWorks")!
  const _component_LayoutMain = _resolveComponent("LayoutMain")!

  return (_openBlock(), _createBlock(_component_LayoutMain, {
    title: _ctx.t('Support ADA pools and help the right ideas'),
    class: "page-home"
  }, {
    "title-right": _withCtx(() => [
      _createVNode(_component_HomePoolStatistics)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PoolList, {
          class: "page-home__section-pool-list",
          "show-pagination": false,
          onOnRowClick: _ctx.handleShowPoolDetails
        }, null, 8, ["onOnRowClick"]),
        _createVNode(_component_a_button, {
          block: true,
          onClick: _ctx.handleShowMorePools
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("More pools")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createVNode(_component_HomeMissions, { class: "page-home__section" }),
      _createVNode(_component_HomeHowItWorks, { class: "page-home__section" })
    ]),
    _: 1
  }, 8, ["title"]))
}